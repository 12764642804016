<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import { useStore } from 'vuex';



/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  setup(){
    const store = useStore();
    const countrieswc  = store.state.countries.filter(d => d.contracts);
    const countrieswoc = store.state.countries.filter(d => !d.contracts);

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      countrieswc,
      countrieswoc
    }
  }
}
</script>

<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Países parte de la subvención</h1>
          <p>
            Actualmente la Subvención Multipaís ALEP y Poblaciones Clave
            financiada por el Fondo Mundial para la lucha contra el VIH/SIDA, la
            tuberculosis y la malaria, se encuentra implementando diversas
            intervenciones en los siguientes países de la región: Guatemala, El
            Salvador, Honduras, Nicaragua, Costa Rica, Panamá, Colombia,
            Ecuador, Bolivia, Perú y Paragua
          </p>
          <div class="ip_separator bottom"></div>
        </div>
      </div>
      <div class="row mt-4 pt-4">
        <div class="col-12">
          <h2>Países con ruta de monitoreo de contratos</h2>
          <p>
            El sistema de monitoreo social está organizado en comités nacionales
            de veeduría, los cuales se han gestionado de manera coordinada y en
            red. Por las características de la calidad de los datos en 6 de los
            11 países se mantiene una ruta activa de monitoreo de los contratos,
            entre otras informaciones, que publican los sitios de gobierno de
            cada uno de los estados.
          </p>
          <ul class="mt-4">
            <li v-for="country of countrieswc" :key="`${country.id}-link`">
              <!-- <router-link :to="{ name: 'Country', params: { country: country.url }}">{{country.name}}</router-link> -->
              <router-link :to="{ name: 'Situation', params: { country: country.url}}" class="od_subnav_btn">{{country.name}}</router-link>
            </li>

          </ul>
          <div class="ip_separator bottom"></div>
        </div>
      </div>
      <div class="row mt-4 pt-4">
        <div class="col-12">
          <h2>Países con ruta de veeduría de datos disponibles</h2>
          <p>
            Asimismo el sistema de monitoreo social promueve la apertura de
            datos de calidad para la veeduría ciudadana. En 5 de los 11 países
            la ruta de veeduría para el acceso a información de calidad nos
            promueve que cada vez más países se sumen a una relación de diálogo
            y transparencia entre los estados y sociedad civil.
          </p>
          <ul class="mt-4">
            <li v-for="country of countrieswoc" :key="`${country.id}-link`">
              <router-link :to="{ name: 'Situation', params: { country: country.url }}" class="od_subnav_btn">{{country.name}}</router-link>
            </li>
          </ul>
          <div class="ip_separator bottom"></div>
        </div>
      </div>
    </div>
  </section>
</template>